/**
 * Render correct component for current step in demand
 */
const components = {
  fi: {
    applicationNotFound: () => import('@fi/pages/ApplicationNotFound'),
    basicInformation: () => import('@fi/pages/steps/BasicInformation'),
    brokeredLoanOffer: () => import('@fi/pages/steps/brokering/BrokeredLoanOffer'),
    brokeringCompletedOffers: () => import('@fi/pages/steps/brokering/BrokeringCompletedOffers'),
    brokeringComplete: () => import('@fi/pages/steps/brokering/BrokeringComplete'),
    businessLoanApplicant: () => import('@fi/pages/steps/businessLoan/BusinessLoanApplicant'),
    businessLoanCompanyInformation: () => import('@fi/pages/steps/businessLoan/BusinessLoanCompanyInformation'),
    businessLoanCompanySelection: () => import('@fi/pages/steps/businessLoan/BusinessLoanCompanySelection'),
    businessLoanComplete: () => import('@fi/pages/steps/businessLoan/BusinessLoanComplete'),
    businessLoanExpired: () => import('@fi/pages/steps/businessLoan/BusinessLoanExpired'),
    businessLoanNoCompanyConnections: () => import('@fi/pages/steps/businessLoan/BusinessLoanCompanySelection'),
    businessLoanOffer: () => import('@fi/pages/steps/businessLoan/BusinessLoanOffer'),
    businessLoanPending: () => import('@fi/pages/steps/businessLoan/BusinessLoanPending'),
    businessLoanProcurationAbstractionRejection: () => import('@fi/pages/steps/businessLoan/businessLoanProcurationAbstractionRejection'),
    businessLoanRejected: () => import('@fi/pages/steps/businessLoan/BusinessLoanRejected'),
    businessLoanSigned: () => import('@fi/pages/steps/businessLoan/BusinessLoanSigned'),
    businessLoanSignees: () => import('@fi/pages/steps/businessLoan/BusinessLoanSignees'),
    businessLoanSigneeStart: () => import('@fi/pages/steps/businessLoan/BusinessLoanStart'),
    businessLoanSigningComplete: () => import('@fi/pages/steps/businessLoan/BusinessLoanSigningComplete'),
    businessLoanSigningPending: () => import('@fi/pages/steps/businessLoan/BusinessLoanSigningPending'),
    businessLoanStart: () => import('@fi/pages/steps/businessLoan/BusinessLoanStart'),
    businessLoanTerms: () => import('@fi/pages/steps/businessLoan/BusinessLoanTerms'),
    carLoanStart: () => import('@fi/pages/steps/Start'),
    carLoanTerms: () => import('@fi/pages/steps/Confirmation'),
    complete: () => import('@fi/pages/steps/Complete'),
    connectionError: () => import('@fi/pages/steps/ConnectionError'),
    continuousLoanComplete: () => import('@fi/pages/steps/Complete'),
    continuousLoanTerms: () => import('@fi/pages/steps/Confirmation'),
    customerQuestions: () => import('@fi/pages/steps/CustomerQuestions'),
    applicationSent: () => import('@fi/pages/steps/ApplicationSent'),
    customerReady: () => import('@fi/pages/steps/CarLoanApplicationCustomerReady'),
    bankAccountSelection: () => import('@fi/pages/steps/BankAccountSelection'),
    instalmentLoanComplete: () => import('@fi/pages/steps/Complete'),
    instalmentLoanTerms: () => import('@fi/pages/steps/Confirmation'),
    kreditz: () => import('@fi/pages/steps/Kreditz'),
    linkExpired: () => import('@fi/pages/LinkExpired'),
    loanOffer: () => import('@fi/pages/steps/LoanOffer'),
    productSelection: () => import('@fi/pages/ProductSelection'),
    reject: () => import('@fi/pages/steps/Rejection'),
    salesDetails: () => import('@fi/pages/steps/CarLoanApplicationSalesDetails'),
    smePosMerchantStart: () => import('@fi/pages/steps/businessLoan/SmePosMerchantStart'),
    smePosCompanyInformation: () => import('@fi/pages/steps/businessLoan/SmePosCompanyInformation'),
    smePosApplicant: () => import('@fi/pages/steps/businessLoan/SmePosApplicant'),
    smePosLoanApplicantStart: () => import('@fi/pages/steps/businessLoan/SmePosLoanApplicantStart'),
    smePosBusinessLoanTerms: () => import('@fi/pages/steps/businessLoan/SmePosApplicantTerms'),
    smePosOwnersInformation: () => import('@fi/pages/steps/businessLoan/SmePosOwnersInformation'),
    smePosMerchantComplete: () => import('@fi/pages/steps/businessLoan/SmePosMerchantComplete'),
    start: () => import('@fi/pages/steps/Start'),
  },
  se: {
    applicationNotFound: () => import('@se/pages/ApplicationNotFound'),
    basicInformation: () => import('@se/pages/steps/BasicInformation'),
    complete: () => import('@se/pages/steps/Complete'),
    connectionError: () => import('@se/pages/steps/ConnectionError'),
    continuousLoanComplete: () => import('@se/pages/steps/Complete'),
    continuousLoanTerms: () => import('@se/pages/steps/Confirmation'),
    customerQuestions: () => import('@se/pages/steps/CustomerQuestions'),
    instalmentLoanComplete: () => import('@se/pages/steps/Complete'),
    instalmentLoanTerms: () => import('@se/pages/steps/Confirmation'),
    kreditz: () => import('@se/pages/steps/Kreditz'),
    linkExpired: () => import('@se/pages/LinkExpired'),
    loanOffer: () => import('@se/pages/steps/LoanOffer'),
    reject: () => import('@se/pages/steps/Rejection'),
    start: () => import('@se/pages/steps/Start'),
  },
  error: () => import('@fi/pages/Error'),
};

export default {
  functional: true,
  props: ['action'],
  render(h, ctx) {
    const country = ctx.parent.$store.getters['application/country'];
    if (Object.prototype.hasOwnProperty.call(components[country], ctx.props.action)) {
      return h(components[country][ctx.props.action], ctx.data, ctx.children);
    }
    return h(components.error, ctx.data, ctx.children, ctx.props);
  },
};
